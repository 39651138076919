import { reactive, unref } from 'vue';
import { useQuery, useMutation, UseQueryOptions } from 'vue-query';
import { QueryKey } from 'vue-query/types';
import {
  getPayment,
  getPayments,
  AddPaymentRequestOptions,
  addCredit,
  GetPaymentRequestOptions,
  PaymentResponse
} from '@/services/api';

import { UsePaymentsOptions } from './interfaces';

export function usePayment(options: GetPaymentRequestOptions, useOptions: Omit<UseQueryOptions<PaymentResponse, unknown, PaymentResponse, QueryKey>, 'queryKey' | 'queryFn'>) {
  return useQuery('payment', () => getPayment(options), useOptions);
}

export function usePayments({ page, keyword }: UsePaymentsOptions = {}) {
  return useQuery(
    reactive(['payments', { page, keyword }]),
    () => getPayments({ query: { page: unref(page), keyword: unref(keyword) } }),
    { keepPreviousData: true }
  );
}

export function useAddCredit() {
  return useMutation((options: AddPaymentRequestOptions) => addCredit(options));
}
